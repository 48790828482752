import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid-cell-photo" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElImage = _resolveComponent("ElImage")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElImage, {
      class: "grid-cell-photo__img",
      src: _ctx.firstPhoto,
      "zoom-rate": 1.2,
      "max-scale": 7,
      "min-scale": 0.2,
      "preview-src-list": _ctx.gallery,
      fit: "contain",
      "preview-teleported": true
    }, null, 8 /* PROPS */, ["src", "preview-src-list"])
  ]))
}