import { mapWritableState } from "pinia";
import { ElDialog, } from "element-plus";
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';
import { approvalTypes, approvalOptions } from "@/app/utils/dictionary";

import BsSingleSelect from '@/app/components/controls/selects/bs-single-select/BsSingleSelect.vue';
import BsSelectV2 from "@/app/components/controls/selects/bs-select-v2/BsSelectV2.vue";
import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import BsInput from '@/app/components/controls/bs-input/BsInput.vue';
import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";

export default {
	name: 'new-targprog-form',
	components: {
		BsSingleSelect,
		BsSelectV2,
		ElDialog,
		BsBtn,
		SvgIcon,
		BsInput,
	},
	props:{
		modelValue: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			required: true
		},
		strokesLength: {
			type: [String, Number],
			required: true
		},
		link: {
			type: String,
			required: true
		},
		label: {
			type: String,
			required: true
		},
		selectedTableRow: {
			type: Object,
			default: []
		}
	},
	emits: ['update:modelValue', 'cancel', 'submit'],
	data() {
		return {
			isVisible: this.modelValue,
			comment: '',
			fieldOptions: [{
				label: '1',
				value: 1
			}],
			approvalType: null,
			yearMonth: null,
			approvalTypes: approvalTypes,
			approvalOptions: approvalOptions,
		}
	},
	computed: {
		...mapWritableState(useDetailTargetProgStore, [
			'detailMonthSelectData',
		]),
		strokes(){
			switch (this.strokesLength) {
				case 1:
					return 'строка'
				case 2 | 3 | 4:
					return 'строки'
				default:
					return 'строк'
			} 
		},
		
		approvalOptionsList() {		
			let options = [];
			const requiredGroups = {
				discount: ['group_approvers_discount', 'group_partner_approvers_discount'],
				changeStatus: ['group_approvers_status', 'group_partner_approvers_status'],
				placementType: ['group_approvers_placement_type', 'group_partner_approvers_placement_type']
			};
		
			let hasDiscount = false;
			let hasChangeStatus = false;
			let hasPlacementType = false;
		
			outer: for (const row of this.selectedTableRow) {
				for (const approval of row.monthData.approvals || []) {
					for (const approver of approval?.approverGroups || []) {
						for (const [key, groups] of Object.entries(requiredGroups)) {
							if (groups.includes(approver?.name)) {
								const option = this.approvalOptions[key];
								if (!options.includes(option)) {
									options.push(option);
								}
								if (key === 'discount') hasDiscount = true;
								if (key === 'changeStatus') hasChangeStatus = true;
								if (key === 'placementType') hasPlacementType = true;
		
								if (hasDiscount && hasChangeStatus && hasPlacementType) {
									break outer;
								}
							}
						}
					}
				}
			}
		
			if (hasDiscount && hasChangeStatus && hasPlacementType) {
				options.unshift(this.approvalOptions['all']);
			}
		
			return options;
		}
		
	},
	methods: {
		cancel(){
			this.$emit('cancel', { popupType: this.type, comment: this.comment, yearMonth: this.yearMonth, approvalType: this.approvalType })
			this.comment = ''
			this.$emit('update:modelValue', false)
		},
		submit(){
			this.$emit('submit', { popupType: this.type, comment: this.comment, yearMonth: this.yearMonth, approvalType: this.approvalType })
			this.comment = ''
			this.$emit('update:modelValue', false)
		},
		changeFilterTemplate(value) {
			this.approvalType = value
		},
		removeTemplate(value) {
			this.approvalType = null
		}
	},
	watch: {
		isVisible(is){
			this.$emit('update:modelValue', is);
			this.activeTab = 'main-info';
		},
		modelValue(is){
			this.isVisible = is;
		}
	},
	created() {

	},
}