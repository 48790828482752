import { mapState, mapActions, mapWritableState } from 'pinia';
import { useNotifStore } from '@/app/stores/notification/notif-store';
import { useUserStore } from '@/app/stores/user-store';

import { QueueList } from '@/app/utils/queue-list';
import { colorList } from '@/app/components/notification/notif-colors-data';

import { ElPopover, ElMessageBox, ElBadge } from "element-plus";

import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";
import BtnHeadNotif from "@/app/components/controls/buttons/btn-head-notif/BtnHeadNotif.vue";
import NotifMessageManager from "@/app/components/header/notif-feed/message-types/notif-message-manager/NotifMessageManager.vue";
import NotifMessageSystem from '@/app/components/header/notif-feed/message-types/notif-message-system/NotifMessageSystem.vue';
import LoaderSpinner from '@/app/components/loaders/loader-spinner/LoaderSpinner.vue';
import api from '@/app/utils/service-api';

const colorQueue = new QueueList({
	list: colorList,
});

export default {
	name: 'NotifFeed',
	components: {
		ElPopover, ElBadge,
		SvgIcon, BtnHeadNotif,
		NotifMessageManager, NotifMessageSystem,
		LoaderSpinner,
	},
	data() {
		return {
			isVisiblePopover: false,
			nextPage: 1,
			maxPage: null,
			notificationsLengthOnPage: 10,
			isLoading: false,
		}
	},
	computed: {
		...mapState(useNotifStore, ['notifData', 'unreadCount']),
		...mapState(useUserStore, ['user']),

		notifList(){
			const list = [];

			for(const item of this.notifData){
				if(item?.senderType=='user'){
					list.push({
						...item,
						colorData: colorQueue.getItem(),
					});
					continue;
				}
				list.push(item);
			}
			// console.log(list);

			return list;
		},

		countNotRead(){
			return this.unreadCount
		}
	},
	methods: {
		...mapActions( useNotifStore, ['updateUnreadCount', 'getUnreadCount', 'getUnreadMessages', 'setNotifData']),

		async setAllMessagesRead(notificationsIds){
			try {
				const res = await api.postData({
					model: `notifications-center/read`,
					data: { notificationsIds }
				});

				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}
		},


		async clickAllCheck(){
			ElMessageBox.confirm(null, null, {
				title: 'Отметить все как прочитанные?',
				// message: 'Вы точно хотите применить изменения?',
				showClose: false,
				customClass: 'bs-message-box',
				// type: 'warning',
			}).then(async() => {
				const notificationsIds = this.notifData.map(item => item.id);
				const res = await this.setAllMessagesRead(notificationsIds)
				this.setNotifData(res)
				this.updateUnreadCount(0)
			}).catch(() => {
				console.log('Отмена');
			})
		},


		async subscribeToNotifications(userId) {
			const channel = api.getPrivateChannel(`notifications_center.user.${userId}`);
			console.log('channel', channel);
	
			if (channel) {
				console.log('channel listen1');
				channel
					.listen('.unread.notifications.count.event', (event) => {
						console.log('Unread notifications count:', event);
						this.updateUnreadCount(event.count);
					})
					// .listen('.update.notifications.list.event', () => {
					//     console.log('Update notifications list event');
					// });
				console.log('listen2');
			}
		},


		async handleScroll(event){
			const { scrollTop, scrollHeight, clientHeight } = event.target;
	
			if (scrollTop + clientHeight >= scrollHeight - 300) {
				await this.loadMoreNotifications();
			}
		},


		async loadMoreNotifications() {
			if (this.isLoading) return;
			this.isLoading = true;
			try {
				if(this.nextPage === this.maxPage) return
				let nextPageParam = this.nextPage + 1
				const newNotifications = await api.getData({
					model: 'notifications-center/unread',
					params: { page: nextPageParam },
				});
				if(newNotifications?.length < this.notificationsLengthOnPage) {
					this.maxPage = this.nextPage
				} else {
					this.nextPage = nextPageParam
				}
				this.setNotifData([...this.notifData, ...newNotifications]);
			} catch (error) {
				console.error('Ошибка при загрузке уведомлений:', error);
			} finally {
				this.isLoading = false;
			}
		},
	},
	async created(){
		console.log(this.notifData);
		this.getUnreadCount()
		await api.initializeWebSocket();
		if (this.user?.id) {
			this.subscribeToNotifications(this.user.id);
		}
	},
	beforeDestroy() {
		if (api.echo) {
		  api.echo.leave(`notifications_center.user.${this.user?.id}`);
		  console.log('Disconnected from notifications channel');
		}
	},
	watch: {
		isVisiblePopover(value) {
			if (value) {
				this.getUnreadMessages()
			}
		},
		user(newUser) {
			if (newUser?.id) {
				console.log("User data updated, subscribing to notifications...");
				this.subscribeToNotifications(newUser.id);
			}
		},
	},
}