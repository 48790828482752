import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "notif-feed" }
const _hoisted_2 = { class: "notif-feed__btn" }
const _hoisted_3 = { class: "notif-feed__container" }
const _hoisted_4 = { class: "notif-feed__head" }
const _hoisted_5 = { class: "notif-feed__list" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BtnHeadNotif = _resolveComponent("BtnHeadNotif")
  const _component_ElBadge = _resolveComponent("ElBadge")
  const _component_RouterLink = _resolveComponent("RouterLink")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_LoaderSpinner = _resolveComponent("LoaderSpinner")
  const _component_NotifMessageManager = _resolveComponent("NotifMessageManager")
  const _component_NotifMessageSystem = _resolveComponent("NotifMessageSystem")
  const _component_ElPopover = _resolveComponent("ElPopover")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElPopover, {
      visible: _ctx.isVisiblePopover,
      "onUpdate:visible": _cache[3] || (_cache[3] = $event => ((_ctx.isVisiblePopover) = $event)),
      placement: "bottom",
      width: 390,
      "popper-class": "notif-feed__popover",
      persistent: false,
      trigger: "click"
    }, {
      reference: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ElBadge, {
            value: _ctx.unreadCount,
            hidden: !_ctx.countNotRead
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BtnHeadNotif)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["value", "hidden"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_RouterLink, {
              class: "notif-feed__center-link",
              to: { name:'notification-center' },
              target: "_blank",
              onClick: _cache[0] || (_cache[0] = $event => (_ctx.isVisiblePopover=false))
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Центр уведомлений")
              ])),
              _: 1 /* STABLE */
            }),
            _createElementVNode("div", {
              class: "notif-feed__all-checked",
              onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.clickAllCheck && _ctx.clickAllCheck(...args)))
            }, [
              _createVNode(_component_SvgIcon, { name: "14-all-checked" })
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.notifData?.length)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "notif-feed__wrapper",
                  onScroll: _cache[2] || (_cache[2] = (...args) => (_ctx.handleScroll && _ctx.handleScroll(...args)))
                }, [
                  (_ctx.isLoading)
                    ? (_openBlock(), _createBlock(_component_LoaderSpinner, { key: 0 }))
                    : _createCommentVNode("v-if", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifData, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "notif-feed__item",
                      key: item.id
                    }, [
                      (item?.senderType=='user')
                        ? (_openBlock(), _createBlock(_component_NotifMessageManager, {
                            key: 0,
                            data: item
                          }, null, 8 /* PROPS */, ["data"]))
                        : (item?.senderType=='system')
                          ? (_openBlock(), _createBlock(_component_NotifMessageSystem, {
                              key: 1,
                              data: item
                            }, null, 8 /* PROPS */, ["data"]))
                          : (item?.notificationType=='tp_row_off_changed_needs_approval')
                            ? (_openBlock(), _createBlock(_component_NotifMessageSystem, {
                                key: 2,
                                data: item
                              }, null, 8 /* PROPS */, ["data"]))
                            : _createCommentVNode("v-if", true)
                    ]))
                  }), 128 /* KEYED_FRAGMENT */))
                ], 32 /* NEED_HYDRATION */))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["visible"])
  ]))
}