import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid-designs-info__block gap-4"
}
const _hoisted_2 = { class: "grid-designs-info__group group-row row-space-between" }
const _hoisted_3 = { class: "grid-designs-info__group group-row" }
const _hoisted_4 = { class: "head-add-tprog-icon" }
const _hoisted_5 = { class: "head-gid" }
const _hoisted_6 = { class: "grid-designs-info__btn-actions" }
const _hoisted_7 = { class: "grid-designs-info__group group-row row-space-between" }
const _hoisted_8 = { class: "grid-designs-info__head" }
const _hoisted_9 = { class: "head-inner-id" }
const _hoisted_10 = { class: "grid-designs-info__type" }
const _hoisted_11 = { class: "grid-designs-info__group group-row" }
const _hoisted_12 = { class: "grid-designs-info__type-construct" }
const _hoisted_13 = { class: "grid-designs-info__type-size" }
const _hoisted_14 = { class: "grid-designs-info__type-construct-icon" }
const _hoisted_15 = { class: "grid-designs-info__group group-row row-space-between" }
const _hoisted_16 = { class: "grid-designs-info__side-select" }
const _hoisted_17 = { class: "grid-designs-info__type-mech" }
const _hoisted_18 = { class: "grid-designs-info__block" }
const _hoisted_19 = { class: "grid-designs-info__group group-row gap-32 row-space-between" }
const _hoisted_20 = ["title"]
const _hoisted_21 = { class: "grid-designs-info__block" }
const _hoisted_22 = { class: "grid-designs-info__group group-row row-space-between" }
const _hoisted_23 = { class: "grid-designs-info__group group-row gap-16" }
const _hoisted_24 = { class: "head-add-tprog-icon" }
const _hoisted_25 = { class: "head-gid" }
const _hoisted_26 = { class: "head-inner-id" }
const _hoisted_27 = { class: "grid-designs-info__side-select" }
const _hoisted_28 = { class: "grid-designs-info__btn-actions" }
const _hoisted_29 = { class: "grid-designs-info__type" }
const _hoisted_30 = { class: "grid-designs-info__group group-row" }
const _hoisted_31 = { class: "grid-designs-info__type-construct" }
const _hoisted_32 = { class: "grid-designs-info__type-size" }
const _hoisted_33 = { class: "grid-designs-info__type-construct-icon" }
const _hoisted_34 = { class: "grid-designs-info__block" }
const _hoisted_35 = { class: "grid-designs-info__group group-row gap-32 row-space-between" }
const _hoisted_36 = ["title"]
const _hoisted_37 = { class: "grid-designs-info__type-mech" }
const _hoisted_38 = {
  key: 0,
  class: "grid-designs-info__block"
}
const _hoisted_39 = {
  key: 0,
  class: "grid-designs-info__block"
}
const _hoisted_40 = { class: "grid-designs-info__group group-row gap-48" }
const _hoisted_41 = { class: "grid-designs-info__group" }
const _hoisted_42 = { class: "grid-designs-info__group-value" }
const _hoisted_43 = { class: "grid-designs-info__group" }
const _hoisted_44 = { class: "grid-designs-info__group-value" }
const _hoisted_45 = { key: 0 }
const _hoisted_46 = { key: 1 }
const _hoisted_47 = { class: "grid-designs-info__group" }
const _hoisted_48 = { class: "grid-designs-info__group-value" }
const _hoisted_49 = { key: 0 }
const _hoisted_50 = { key: 1 }
const _hoisted_51 = { class: "grid-designs-info__block" }
const _hoisted_52 = { class: "grid-designs-info__group group-row gap-32 align-start" }
const _hoisted_53 = { class: "grid-designs-info__group" }
const _hoisted_54 = ["title"]
const _hoisted_55 = {
  key: 1,
  class: "grid-designs-info__group-value"
}
const _hoisted_56 = { class: "grid-designs-info__group" }
const _hoisted_57 = ["title"]
const _hoisted_58 = {
  key: 1,
  class: "grid-designs-info__group-value"
}
const _hoisted_59 = { class: "grid-designs-info__group" }
const _hoisted_60 = { class: "grid-designs-info__group-value" }
const _hoisted_61 = { key: 0 }
const _hoisted_62 = { key: 1 }
const _hoisted_63 = {
  key: 0,
  class: "grid-designs-info__group"
}
const _hoisted_64 = { class: "grid-designs-info__group-value" }
const _hoisted_65 = ["title"]
const _hoisted_66 = { key: 1 }
const _hoisted_67 = { class: "grid-designs-info__block" }
const _hoisted_68 = { class: "grid-designs-info__group" }
const _hoisted_69 = { class: "grid-designs-info__group-value" }
const _hoisted_70 = { key: 0 }
const _hoisted_71 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_SideSelect = _resolveComponent("SideSelect")
  const _component_ElCollapseTransition = _resolveComponent("ElCollapseTransition")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["grid-designs-info", { 'grid-designs-info--open': _ctx.isOpen, 'grid-designs-info--is-compact': _ctx.compact }])
  }, [
    (_ctx.compact)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", {
                class: "grid-designs-info__head",
                title: "Открыть РК",
                onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.openSideConstruct && _ctx.openSideConstruct(...args)))
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_4, [
                  _createVNode(_component_SvgIcon, { name: "12-circle-check-filled" })
                ], 512 /* NEED_PATCH */), [
                  [_vShow, _ctx.sideData?.inTargetProgram]
                ]),
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.sideData.innerId), 1 /* TEXT */)
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", {
                class: "grid-designs-info__btn-actions-item",
                title: "добавить в АП",
                onClick: _cache[1] || (_cache[1] = $event => (_ctx.isShowTpCart? _ctx.addTargetCart(): _ctx.clickAddTarget()))
              }, [
                _createVNode(_component_SvgIcon, { name: "12-plus" })
              ]),
              _createElementVNode("div", {
                class: "grid-designs-info__btn-actions-item",
                title: "Поделиться",
                onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.clickCopyShareLink && _ctx.clickCopyShareLink(...args)))
              }, [
                _createVNode(_component_SvgIcon, { name: "16-share" })
              ]),
              _createElementVNode("div", {
                class: "grid-designs-info__btn-actions-item",
                title: "Открыть тех.требования",
                onClick: _cache[3] || (_cache[3] = (...args) => (_ctx.openTechReq && _ctx.openTechReq(...args)))
              }, [
                _createVNode(_component_SvgIcon, { name: "16-collection" })
              ]),
              _createElementVNode("div", {
                class: "grid-designs-info__btn-actions-item",
                title: "Добавить в избранное",
                onClick: _cache[4] || (_cache[4] = (...args) => (_ctx.clickAddWishList && _ctx.clickAddWishList(...args)))
              }, [
                _createVNode(_component_SvgIcon, { name: "12-star" })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.sideData.gid), 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.rowData.constructType), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.sideData.sideSize), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_SvgIcon, {
                    name: _ctx.getTypeDesignIcon(_ctx.rowData.constructType)
                  }, null, 8 /* PROPS */, ["name"])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_SideSelect, {
                modelValue: _ctx.rowData.sideSelectData.value,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.rowData.sideSelectData.value) = $event)),
                options: _ctx.rowData.sideSelectData.options,
                onChange: _cache[6] || (_cache[6] = () => {})
              }, null, 8 /* PROPS */, ["modelValue", "options"])
            ]),
            _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.sideData.sideMech), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", {
                class: "grid-designs-info__address",
                href: "#",
                title: _ctx.rowData.address
              }, _toDisplayString(_ctx.labelCity), 9 /* TEXT, PROPS */, _hoisted_20)
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", {
                  class: "grid-designs-info__head",
                  title: "Открыть РК",
                  onClick: _cache[7] || (_cache[7] = (...args) => (_ctx.openSideConstruct && _ctx.openSideConstruct(...args)))
                }, [
                  _withDirectives(_createElementVNode("span", _hoisted_24, [
                    _createVNode(_component_SvgIcon, { name: "12-circle-check-filled" })
                  ], 512 /* NEED_PATCH */), [
                    [_vShow, _ctx.sideData?.inTargetProgram]
                  ]),
                  _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.sideData?.innerId), 1 /* TEXT */),
                  _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.sideData?.gid), 1 /* TEXT */)
                ]),
                _createElementVNode("div", _hoisted_27, [
                  _createVNode(_component_SideSelect, {
                    modelValue: _ctx.rowData.sideSelectData.value,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.rowData.sideSelectData.value) = $event)),
                    options: _ctx.rowData.sideSelectData.options,
                    onChange: _cache[9] || (_cache[9] = () => {})
                  }, null, 8 /* PROPS */, ["modelValue", "options"])
                ]),
                _createElementVNode("div", {
                  class: "grid-designs-info__btn-open",
                  onClick: _cache[10] || (_cache[10] = (...args) => (_ctx.toggleWrap && _ctx.toggleWrap(...args)))
                }, [
                  _createVNode(_component_SvgIcon, { name: "14-arrow-down" })
                ])
              ]),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", {
                  class: "grid-designs-info__btn-actions-item",
                  title: "добавить в АП",
                  onClick: _cache[11] || (_cache[11] = $event => (_ctx.isShowTpCart? _ctx.addTargetCart(): _ctx.clickAddTarget()))
                }, [
                  _createVNode(_component_SvgIcon, { name: "12-plus" })
                ]),
                _createElementVNode("div", {
                  class: "grid-designs-info__btn-actions-item",
                  title: "Поделиться",
                  onClick: _cache[12] || (_cache[12] = (...args) => (_ctx.clickCopyShareLink && _ctx.clickCopyShareLink(...args)))
                }, [
                  _createVNode(_component_SvgIcon, { name: "16-share" })
                ]),
                _createElementVNode("div", {
                  class: "grid-designs-info__btn-actions-item",
                  title: "Открыть тех.требования",
                  onClick: _cache[13] || (_cache[13] = (...args) => (_ctx.openTechReq && _ctx.openTechReq(...args)))
                }, [
                  _createVNode(_component_SvgIcon, { name: "16-collection" })
                ]),
                _createElementVNode("div", {
                  class: "grid-designs-info__btn-actions-item",
                  title: "Добавить в избранное",
                  onClick: _cache[14] || (_cache[14] = (...args) => (_ctx.clickAddWishList && _ctx.clickAddWishList(...args)))
                }, [
                  _createVNode(_component_SvgIcon, { name: "12-star" })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.rowData.constructType), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.sideData.sideSize), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_33, [
                  _createVNode(_component_SvgIcon, {
                    name: _ctx.getTypeDesignIcon(_ctx.rowData.constructType)
                  }, null, 8 /* PROPS */, ["name"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_34, [
              _createElementVNode("div", _hoisted_35, [
                _createElementVNode("div", {
                  class: "grid-designs-info__address",
                  href: "#",
                  title: _ctx.rowData.address
                }, _toDisplayString(_ctx.rowData.address), 9 /* TEXT, PROPS */, _hoisted_36),
                _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.sideData.sideMech), 1 /* TEXT */)
              ])
            ])
          ]),
          _createVNode(_component_ElCollapseTransition, null, {
            default: _withCtx(() => [
              (_ctx.isOpen)
                ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                    (_ctx.sideData?.digitalData)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                          _createElementVNode("div", _hoisted_40, [
                            _createElementVNode("div", _hoisted_41, [
                              _cache[15] || (_cache[15] = _createElementVNode("div", { class: "grid-designs-info__group-label" }, "Блок/слот", -1 /* HOISTED */)),
                              _createElementVNode("div", _hoisted_42, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.sideData.digitalData.blockDuration || 0) + " сек/", 1 /* TEXT */),
                                _createElementVNode("span", null, _toDisplayString(_ctx.sideData.digitalData.slotDuration || 0) + " сек", 1 /* TEXT */)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_43, [
                              _cache[16] || (_cache[16] = _createElementVNode("div", { class: "grid-designs-info__group-label" }, "Время работы", -1 /* HOISTED */)),
                              _createElementVNode("div", _hoisted_44, [
                                (_ctx.sideData?.workingHours)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_45, _toDisplayString(_ctx.sideData?.workingHours), 1 /* TEXT */))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_46, "–"))
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_47, [
                              _cache[17] || (_cache[17] = _createElementVNode("div", { class: "grid-designs-info__group-label" }, "WiFi ловушка", -1 /* HOISTED */)),
                              _createElementVNode("div", _hoisted_48, [
                                (_ctx.sideData?.wifiTrap)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_49, _toDisplayString(_ctx.sideData?.wifiTrap), 1 /* TEXT */))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_50, "–"))
                              ])
                            ])
                          ])
                        ]))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("div", _hoisted_51, [
                      _createElementVNode("div", _hoisted_52, [
                        _createElementVNode("div", _hoisted_53, [
                          _cache[18] || (_cache[18] = _createElementVNode("div", { class: "grid-designs-info__group-label" }, "Оператор", -1 /* HOISTED */)),
                          (_ctx.sideData?.operator)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                class: "a grid-designs-info__group-value",
                                href: "#",
                                title: _ctx.sideData?.operator
                              }, _toDisplayString(_ctx.sideData?.operator), 9 /* TEXT, PROPS */, _hoisted_54))
                            : (_openBlock(), _createElementBlock("div", _hoisted_55, "–"))
                        ]),
                        _createElementVNode("div", _hoisted_56, [
                          _cache[19] || (_cache[19] = _createElementVNode("div", { class: "grid-designs-info__group-label" }, "Собственник", -1 /* HOISTED */)),
                          (_ctx.sideData?.owner)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                class: "a grid-designs-info__group-value",
                                href: "#",
                                title: _ctx.sideData?.owner
                              }, _toDisplayString(_ctx.sideData?.owner), 9 /* TEXT, PROPS */, _hoisted_57))
                            : (_openBlock(), _createElementBlock("div", _hoisted_58, "–"))
                        ]),
                        _createElementVNode("div", _hoisted_59, [
                          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "grid-designs-info__group-label" }, "ESPAR ID", -1 /* HOISTED */)),
                          _createElementVNode("div", _hoisted_60, [
                            (_ctx.sideData?.esparId)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_61, _toDisplayString(_ctx.sideData?.esparId), 1 /* TEXT */))
                              : (_openBlock(), _createElementBlock("span", _hoisted_62, "–"))
                          ])
                        ]),
                        (!_ctx.sideData?.digitalData)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_63, [
                              _cache[21] || (_cache[21] = _createElementVNode("div", { class: "grid-designs-info__group-label" }, "Материал", -1 /* HOISTED */)),
                              _createElementVNode("div", _hoisted_64, [
                                (_ctx.sideData?.staticMaterial)
                                  ? (_openBlock(), _createElementBlock("span", {
                                      key: 0,
                                      title: _ctx.sideData?.staticMaterial
                                    }, _toDisplayString(_ctx.sideData?.staticMaterial), 9 /* TEXT, PROPS */, _hoisted_65))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_66, "–"))
                              ])
                            ]))
                          : _createCommentVNode("v-if", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_67, [
                      _createElementVNode("div", _hoisted_68, [
                        _cache[22] || (_cache[22] = _createElementVNode("div", { class: "grid-designs-info__group-label" }, "Технический комментарий:", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_69, [
                          (_ctx.sideData?.techComment)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_70, _toDisplayString(_ctx.sideData?.techComment), 1 /* TEXT */))
                            : (_openBlock(), _createElementBlock("span", _hoisted_71, "–"))
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          })
        ], 64 /* STABLE_FRAGMENT */))
  ], 2 /* CLASS */))
}