import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "side-queue-item" }
const _hoisted_2 = { class: "side-queue-item__index" }
const _hoisted_3 = {
  key: 0,
  class: "side-queue-item__status"
}
const _hoisted_4 = {
  key: 0,
  class: "success"
}
const _hoisted_5 = {
  key: 0,
  class: "side-queue-item__date"
}
const _hoisted_6 = { class: "side-queue-item__target-id" }
const _hoisted_7 = { class: "side-queue-item__target-name" }
const _hoisted_8 = { class: "side-queue-item__user-name" }
const _hoisted_9 = { class: "side-queue-item__periods" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElTag = _resolveComponent("ElTag")
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString((parseInt(_ctx.data?.position, 10) || 1) ?? 1), 1 /* TEXT */),
    (_ctx.data?.status)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_ElTag, {
            type: _ctx.tagType,
            "disable-transitions": true
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.status), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["type"]),
          (this.data?.time)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "(" + _toDisplayString(_ctx.time) + ")", 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.data?.status && _ctx.data.status != 'unavailable' && _ctx.data.status != 'Свобода')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.data.date)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.data.date), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_6, "[" + _toDisplayString(_ctx.data.targetProgram?.id) + "]", 1 /* TEXT */),
          _createElementVNode("div", _hoisted_7, "\"" + _toDisplayString(_ctx.data.targetProgram?.name) + "\"", 1 /* TEXT */),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.data.manager?.name), 1 /* TEXT */),
          (_ctx.data.manager?.phone)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "side-queue-item__phone",
                onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.clickPhone && _ctx.clickPhone(...args))),
                title: "Копировать номер телефона"
              }, [
                _createVNode(_component_SvgIcon, { name: "16-gg-phone" })
              ]))
            : _createCommentVNode("v-if", true),
          (_ctx.data.manager?.email)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "side-queue-item__email",
                onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.clickEmail && _ctx.clickEmail(...args))),
                title: "Копировать email"
              }, [
                _createVNode(_component_SvgIcon, { name: "16-email-solid" })
              ]))
            : _createCommentVNode("v-if", true),
          (_ctx.data.manager?.tgId)
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: "side-queue-item__telegram",
                onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.clickTelegram && _ctx.clickTelegram(...args))),
                title: "Копировать telegram"
              }, [
                _createVNode(_component_SvgIcon, { name: "12-telegram" })
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.formatPeriods), 1 /* TEXT */)
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true)
  ]))
}