const defaultDataAdapter = [
	{
		key: 'name',
		keyBack: '',
		keyBackUpdate: 'name',
	},
	{
		key: 'pricePolicy',
		keyBack: '',
		keyBackUpdate: 'price_policy_id',
	},
];

const personDataAdapter = [
	{
		key: 'rimPerson',
		keyBack: 'rimLegalEntity',
		keyBackUpdate: 'rim_legal_entity_id',
		keyOptions: 'rim_legal_entity',
	},
	{
		key: 'clientPerson',
		keyBack: 'customerLegalEntity',
		keyBackUpdate: 'customer_legal_entity_id',
		keyOptions: 'customer_legal_entity',
	},
	{
		key: 'printPerson',
		keyBack: 'printer',
		keyBackUpdate: 'printer_id',
		keyOptions: 'printer',
	},
	{
		key: 'installPerson',
		keyBack: 'installer',
		keyBackUpdate: 'installer_id',
		keyOptions: 'installer',
	},
	{
		key: 'designPerson',
		keyBack: 'designer',
		keyBackUpdate: 'designer_id',
		keyOptions: 'designer',
	},
	{
		key: 'photoReportPerson',
		keyBack: 'photoReporter',
		keyBackUpdate: 'photo_reporter_id',
		keyOptions: 'photo_reporter',
	},
	{
		key: 'ownerLegalEntity',
		keyBack: 'ownerLegalEntity',
		keyBackUpdate: 'owner_legal_entity_id',
		keyOptions: 'rimOrPartnerLegalEntities',
	},
];

const summaryDataAdapter = [
	{
		key: 'client',
		keyBack: 'customer',
		keyBackUpdate: 'customer_id',
		keyOptions: 'customer',
	},
	{
		key: 'brand',
		keyBack: 'brand',
		keyBackUpdate: 'brand_id',
		keyOptions: 'brand',
	},
	{
		key: 'agency',
		keyBack: 'agency',
		keyBackUpdate: 'agency_id',
		keyOptions: 'agency',
	},
	{
		key: 'clientCategory',
		keyBack: 'customerCategory',
		keyBackUpdate: 'customer_category',
		keyOptions: 'customer_category',
	},
	{
		key: 'placementType',
		keyBack: 'placementType',
		keyBackUpdate: 'placement_type',
		keyOptions: 'placement_type',
	},
	{
		key: 'period',
		keyBack: 'period',
		keyBackUpdate: 'period',
	},
	{
		key: 'department',
		keyBack: 'department',
		keyBackUpdate: 'department_id',
		keyOptions: 'department',
	},
	{
		key: 'responsibleManager',
		keyBack: 'responsibleManager',
		keyBackUpdate: '',
		keyOptions: 'managers',
	},
	{
		key: 'observerManagers',
		keyBack: 'managers',
		keyBackUpdate: '',
		keyOptions: 'managers',
	},
];

const otherDataAdapter = [
	{
		key: 'product',
		keyBack: 'product',
		keyBackUpdate: '',
	},
	{
		key: 'customerCategory',
		keyBack: 'customerCategory',
		keyBackUpdate: '',
	},
];

const changeOrderMonthAdapter = {
	status:{
		param:'change-status',
		keyField: 'status',
	},
	periodInMonthDays:{
		param:'change-periods',
		keyField: 'periods',
	},
	placeType: {
		param:'change-placement-type',
		keyField: 'placementType',
	},
	product:{
		param:'change-product',
		keyField: 'productId',
	},
	manager:{
		param:'change-manager',
		keyField: 'managerId',
	},
	customerCategory:{
		param:'change-customer-category',
		keyField: 'customerCategoryId',
	},
	promotion:{
		param:'change-promotion',
		keyField: 'promotionId',
	},
	engManager:{
		param:'change-eng-manager',
		keyField: 'endManagerId',
	},
	dealLink:{
		param:'change-bitrix-deal-url',
		keyField: 'bitrixDealUrl',
	},
	taskLink:{
		param:'change-bitrix-task-url',
		keyField: 'bitrixTaskUrl',
	},
	photoReportLink:{
		param:'change-photo-report-link',
		keyField: 'photoReportLink',
	},

	frequency:{
		param:'change-frequency',
		keyField: 'frequency',
	},

	isVAT:{
		param:'change-vat',
		keyField: 'vat',
	},
	pricePlacePeriodDiscount:{
		param:'change-discount',
		keyField: 'discount',
	},
	pricePlacePeriodNotVAT:{
		param:'change-cost',
		keyField: 'cost',
	},
	coefficient:{
		param:'change-coefficient',
		keyField: 'coefficient',
	},
	firstCost:{
		param:'change-first-cost',
		keyField: 'firstCost',
	},
	agentCommission:{
		param:'change-agent-commission',
		keyField: 'agentCommission',
	},
	clientCommission:{
		param:'change-customer-commission',
		keyField: 'customerCommission',
	},

	// желтые поля
	install:{
		keyField: 'installation',
	},
	addInstall:{
		keyField: 'additional_installation',
	},
	print:{
		keyField: 'print',
	},
	speedPrint: {
		keyField: 'urgent_print',
	},
	design: {
		keyField: 'design',
	},
	photoReport: {
		keyField: 'photo_report',
	},

	// красные поля
	clientPerson:{
		param:'change-customer',
		keyField: 'customerId',
	},
	rimPerson:{
		param:'change-rim-legal-entity',
		keyField: 'rimLegalEntityId',
	},
	operator:{
		param:'change-operator',
		keyField: 'operatorId',
	},
	printPerson:{
		param:'change-printer',
		keyField: 'printerId',
	},
	installPerson:{
		param:'change-installer',
		keyField: 'installerId',
	},
	designPerson:{
		param:'change-designer',
		keyField: 'designerId',
	},
	photoReportPerson:{
		param:'change-photo-reporter',
		keyField: 'photoReporterId',
	}

}

export { defaultDataAdapter, personDataAdapter, summaryDataAdapter, otherDataAdapter, changeOrderMonthAdapter };