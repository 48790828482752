import { defineComponent } from "vue"

export default defineComponent({
	name: 'svg-icon',
	props:{
		name: String,
	},
	data() {
		return {
			hash: __webpack_hash__,
		}
	},
});