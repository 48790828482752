import { defineComponent } from "vue";
import cellMixin from "@/app/mixins/table/cell-mixin";

import { ElImage } from 'element-plus';

export default defineComponent({
	name: 'grid-cell-photo',
	mixins: [cellMixin],
	components: {
		ElImage,
	},
	computed: {
		sideData(){
			return this.rowData?.curSideData || {};
		},
		firstPhoto(){
			const { photoList } = this.sideData;
			if(!photoList?.length) return '';

			const firstPhoto = photoList[0] ?? {};
			const photo = firstPhoto?.['178'] ?? '';

			return photo;
		},
		gallery(){
			const { photoList } = this.sideData;
			let gallery = [];
			if(!photoList?.length) return gallery;

			gallery = photoList.map(item=>{
				return item?.['1920'] ?? '';
			});

			return gallery;
		}
	},
});