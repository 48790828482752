import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tgth-all-check-settings" }
const _hoisted_2 = { class: "tgth-all-check-settings__popover-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsRadioGroup = _resolveComponent("BsRadioGroup")
  const _component_ElPopover = _resolveComponent("ElPopover")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElPopover, {
      visible: _ctx.isVisiblePopover,
      "onUpdate:visible": _cache[1] || (_cache[1] = $event => ((_ctx.isVisiblePopover) = $event)),
      trigger: "hover",
      persistent: false,
      width: "auto",
      placement: "top-start",
      "popper-class": "tgth-all-check-settings__popover",
      "popper-options": _ctx.popoverOptions
    }, {
      reference: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BsRadioGroup, {
            modelValue: _ctx.checkValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.checkValue) = $event)),
            options: _ctx.radioOptions
          }, null, 8 /* PROPS */, ["modelValue", "options"])
        ])
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["visible", "popper-options"])
  ]))
}