import TableGridTh from "../table-grid-th/TableGridTh.vue";
import BsCheckbox from "@/app/components/controls/bs-checkbox/BsCheckbox.vue";
import TgthAllCheckSettings from "./tgth-all-check-settings/TgthAllCheckSettings.vue";

export default {
	name: 'TgThAllCheck',
	components: {
		TableGridTh, BsCheckbox, TgthAllCheckSettings,
	},
	props: {
		isCheck: Boolean,
		indeterminate: Boolean,
		rowspan: {
			type: [String, Number],
			default: 1,
		},

		checkedAllPage: Boolean,
		settingsValue: {
			type: String,
			default: '',
		}
	},
	emits: ['change', 'changeCheckSettings', 'update:settingsValue'],
	data() {
		return {
			modelSettingsValue: this.settingsValue,
		}
	},
	methods: {
		clickTh(){
			this.$emit('change', !this.isCheck);
		},
		changeCheckbox(is){
			this.$emit('change', is);
		},
		changeCheckSettings(val){
			this.$emit('changeCheckSettings', val);
		}
	},
	watch: {
		settingsValue(val){
			this.modelSettingsValue = val;
		},
		modelSettingsValue(val){
			this.$emit('update:settingsValue', val);
		}
	},
}