export default {
	name: 'surface-month-item',
	props: {
		data: {
			type: Object,
			default: {},
		},
	},
	data() {
		return {

		}
	},
	computed:{
		type(){
			if(this.data?.chunks) return 'multi';
			return 'single';
		},
		status(){
			return this.data?.status || '';
		},
		chunkList(){
			return this.data.chunks;
		},
		classTypeList(){
			return {
				'type-single': this.type=='single',
				'type-multi': this.type=='multi',
			}
		},
		classStatusList(){
			if(this.type=='multi') return {};

			return {
				'status-free': this.status=='free',
				'status-reserved': this.status=='reserved',
				'status-busy': this.status=='busy',
				'status-dismounted': this.status=='dismounted',
				'status-fract': this.data.isFract,
				'type-added': this.data.added,
			}
		},
	}
}