import { ElPopover } from "element-plus";
import BsRadioGroup from "@/app/components/controls/bs-radio-group/BsRadioGroup.vue";

export default {
	name: 'TgthAllCheckSettings',
	components: {
		ElPopover,
		BsRadioGroup,
	},
	props: {
		modelValue: {
			type: String,
			default: 'check-one-page',
		},
	},
	emits: ['update:modelValue', 'change'],
	data() {
		return {
			isVisiblePopover: false,
			checkValue: this.modelValue,
			popoverOptions: {
				modifiers: [
					{
						name: 'offset',
						options: {
							offset: [-20, 12],
						},
					},
				],
			},
			radioOptions: [
				{
					value: 'check-one-page',
					label: 'Выбрать на странице',
				},
				{
					value: 'check-all-page',
					label: 'Выбрать всё',
				},
			],
		}
	},
	watch: {
		modelValue(val){
			this.checkValue = val;
		},
		checkValue(val){
			this.$emit('update:modelValue', val);
			this.$emit('change', val);
		}
	},
}