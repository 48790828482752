export const statuses = {
    free: {
        value: 'free',
        label: 'Свободно',
    },
    reserved: {
        value: 'reserved',
        label: 'Резерв',
    },
    temporary_approved: {
        value: 'temporary_approved',
        label: 'Временное подтверждение',
    },
    approved: {
        value: 'approved',
        label: 'Подтверждение',
    },
};


export const approvalTypes = [
    {
        value: 'all',
        label: 'Все',
        path: 'all'
    },
    {
        value: 'discount',
        label: 'Скидка',
        path: 'discount'
    },
    {
        value: 'changeStatus',
        label: 'Статус',
        path: 'status'
    },
    {
        value: 'placementType',
        label: 'Тип рамещения',
        path: 'placement'
    },
]
export const approvalOptions = {
    all: {
        value: 'all',
        label: 'Все',
        path: 'all'
    },
    discount: {
        value: 'discount',
        label: 'Скидка',
        path: 'discount'
    },
    changeStatus: {
        value: 'changeStatus',
        label: 'Статус',
        path: 'status'
    },
    placementType: {
        value: 'placementType',
        label: 'Тип рамещения',
        path: 'placement'
    },
}